<template>
  <div>
    <b-card :title="photos.title">
      <CoolLightBox
        :items="photos.images"
        :index="index"
        @close="index = null"
      />
      <b-row>
        <b-col
          v-for="(item,imageIndex) in photos.images"
          :key="item.id"
          cols="12"
          md="3"
          class="mb-1"
        >
          <div class="photo-container">
            <div class="rounded overflow-hidden height-300 d-flex align-items-center">
              <b-img
                :src="folderUrl + item.name"
                class="cursor-pointer"
                center
                rounded
                @click="index = imageIndex"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BImg,
} from 'bootstrap-vue'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'GalleryPhotos',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    CoolLightBox,
  },
  data() {
    return {
      index: null,
      folderUrl: `${this.$store.state.app.baseURL}/media/portal/gallery/`,
    }
  },
  computed: {
    photos() {
      return this.$store.getters['gallery/getPhotos']
    },
  },
  created() {
    this.getPhotos()
  },
  methods: {
    getPhotos() {
      this.$store.dispatch('gallery/photos', {
        where: {
          'com_portal_gallery.id': this.$route.params.id,
        },
        array: false,
      })
    },
  },
}
</script>
<style scoped>
.photo-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
